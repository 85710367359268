@import 'theme/base.scss';

.navBar {
  border-bottom: solid $gray-200 1px;
  border-top: solid $green 4px;
}

.logobranded {
  height: 42px;
}

.logounbranded {
  height: 25px;
}

.navBar:global(.navbar-dark) {
  &:global(.bg-dark) {
    background-color: $gray-10 !important;
  }
  :global(.navbar-nav .nav-link) {
    color: $gray-300;
    &:global(:hover),
    &:global(:active) {
      color: $gray-100;
    }
  }
}

@include media-breakpoint-up(sm) {
  .logobranded {
    height: 70px;
  }

  .logounbranded {
    height: 45px;
  }
  .navBar:global(.navbar-light .navbar-nav) {
    height: 3.75rem;
    align-items: center;
    :global(.nav-link) {
      padding: 0.75rem 1.5rem;
    }
    :global(.dropdown) {
      &:global(.show) {
        border-radius: 2px;
      }
      :global(.dropdown-menu) {
        margin-top: 0.5rem;
        padding: 0;
        border: 0.1rem solid $gray-300;
        :global(.dropdown-item) {
          border-top: 0.1rem solid $gray-300;
          padding: 1rem;
          &:global(.active),
          &:global(:active) {
            font-weight: 600;
            color: initial;
            background-color: initial;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  .navBar:global(.navbar-light .navbar-nav) {
    padding-bottom: 0.5rem;
    border-top: 0.1rem solid $gray-200;
    margin-top: 1rem;
    :global(.nav-link) {
      padding: 1rem;
    }
    :global(.dropdown-menu) {
      margin: 0;
      padding: 0;
      :global(.dropdown-item) {
        padding: 1rem 2.5rem;
        &:global(:active),
        &:global(.active) {
          font-weight: 600;
          color: initial;
          background-color: initial;
        }
      }
    }
  }
}
