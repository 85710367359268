.navbar-dark {
  .nav-link {
    padding: $nav-link-padding-y + (1rem - $font-size-sm) *0.5 $nav-link-padding-x; // the font is sm instead of 1rem, so we add padding the keep the nav height the same.
  }
}

//
// Underline
.nav-underline {
  border-bottom: 0;

  .nav-link {
    font-size: $h3-font-size;
    color: $gray-500;
    border: 0;
    border-bottom: 3px solid transparent;

    &.disabled {
      color: $nav-link-disabled-color;
      background-color: transparent;
      border-color: transparent;
    }
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    color: $gray-900;
    border-bottom-color: $green;
  }

  .dropdown-menu {
    // Make dropdown border overlap tab border
    margin-top: -$nav-tabs-border-width;
    // Remove the top rounded corners here since there is a hard edge above the menu
    @include border-top-radius(0);
  }
}
