.fullscreenContainer {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2000;
  height: 100%;
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(152, 189, 218, 0.41);
  backdrop-filter: blur(0.4rem) contrast(15%) brightness(120%);
}

.contentContainer {
  align-self: center;
  align-content: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
