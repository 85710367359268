@import 'theme/base.scss';

.toolButtonWithDescription {
  @include media-breakpoint-down(xs) {
    height: 7rem;
    text-align: left;
    margin: 0.75rem 0;
    :global(hr) {
      display: none;
    }
  }
  @include media-breakpoint-up(sm) {
    width: 14rem;
    height: 18rem;
    text-align: center;
    margin: 0.75rem;
    .icon {
      width: 80%;
      margin-top: -2rem;
      margin-bottom: -1rem;
    }
    .description {
      padding: 0 1.25rem;
      text-align: left;
    }
  }
  @include media-breakpoint-up(lg) {
    width: 15rem;
    height: 19rem;
  }
  display: flex;
  flex-direction: column;
  border-color: $gray-100;
  box-shadow: 0 0 0.25rem 0.05rem $gray-300;
  padding: 1.25rem;

  &:hover,
  &:active {
    cursor: pointer;
    box-shadow: 0 0 0.5rem 0.05rem $gray-300;
    background-color: $gray-100;
    border-color: $gray-100;
    color: $blue;
    text-decoration: none;
    .description {
      color: black;
    }
  }
}
.toolButton {
  @include media-breakpoint-up(xs) {
    width: 9.5rem;
    height: 10rem;
  }
  @include media-breakpoint-up(md) {
    width: 7.5rem;
    height: 9rem;
  }
  @include media-breakpoint-up(lg) {
    width: 10.5rem;
    height: 11rem;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-color: $gray-100;
  box-shadow: 0 0 0.25rem 0.05rem $gray-300;
  margin: 0.5rem;
  padding: 1.25rem;

  &:hover,
  &:active {
    cursor: pointer;
    box-shadow: 0 0 0.5rem 0.05rem $gray-300;
    background-color: $white;
    border-color: $gray-100;
    color: $blue;
    text-decoration: none;
  }
}
.toolButtonText {
  color: $blue;
}
