$line-height-standard: 1.45;
//
// Color system
// private/stylesheets/generated_shared_styles/color_palette/_mkiColorVariables.scss
$gray-10: #222325;
$gray-20--darker: #353538;
$gray-20: #3d3e40;
$gray-40--darker: #69696b;
$gray-50--darker: #828285;
$gray-70--lighter: #c5c5c7;
$gray-80--lighter: #dedfe0;
$gray-90--lighter: #fafafa;
$gray-90--darker: #ebebeb;

$green--lightest: #def2d5;
$green: #67b346;
$green--darker: #42732d;

$blue--lightest: #f1f7fd;
$blue--lighter: #aacef2;
$blue--light: #dcebfa;
$blue: #1470cc;
$blue--darker: #0f5499;
$blue--darkest: #0b3c6e;

$red--lightest: #fae1dc;
$red: #e64d2e;
$red--darker: #c7290a;
$red--darkest: #992008;

$yellow--lightest: #fff5e0;
$yellow--darker: #f2a200;

$sizes: (
  10: 10%,
  20: 20%,
  25: 25%,
  30: 30%,
  40: 40%,
  50: 50%,
  60: 60%,
  70: 70%,
  75: 75%,
  80: 80%,
  90: 90%,
  100: 100%,
  auto: auto,
);
