@import 'theme/base.scss';

.cardContainer {
  display: flex;
  flex: 1 1 auto;
  justify-content: center;

  .cardContainerColumn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 60rem;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    @include media-breakpoint-up(md) {
      width: 80%;
    }

    @include media-breakpoint-up(xl) {
      width: 70%;
    }

    .card {
      @include media-breakpoint-up(md) {
        border: 1px solid $gray-80--lighter;
        margin: 5rem 0;
        padding: 2rem 5.5rem 4rem 5.5rem;
      }

      @include media-breakpoint-down(sm) {
        padding: 0 17px;
        margin-bottom: 3rem;
      }

      .cardIcon {
        margin: 30px auto;

        @include media-breakpoint-down(sm) {
          width: 80px;
        }

        @include media-breakpoint-up(md) {
          width: 100px;
        }
      }

      .cardHeader {
        h1 {
          font-weight: 300;
          font-size: 24px;
          text-align: center;
        }
      }

      .cardBody {
        h3 {
          font-weight: 300;
          margin-top: 3rem;

          @include media-breakpoint-down(sm) {
            font-size: 1.2rem;
          }

          @include media-breakpoint-up(md) {
            font-size: 1.4rem;
          }
        }

        p {
          margin: 1rem 0;

          @include media-breakpoint-down(sm) {
            font-size: 1rem;
          }

          @include media-breakpoint-up(md) {
            font-size: 1.2rem;
          }
        }
      }
    }

    .cardFootnote {
      @include media-breakpoint-down(sm) {
        padding: 0 1rem;
      }

      @include media-breakpoint-up(md) {
        padding: 0 5.5rem;
        margin-bottom: 3rem;
      }

      p {
        @include media-breakpoint-down(sm) {
          font-size: 0.8rem;
        }

        @include media-breakpoint-up(md) {
          font-size: 1.1rem;
        }
      }
    }
  }
}
