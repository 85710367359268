/* CiscoSans Fonts for Dashboard
 *  modified from private/stylesheets/_font.scss
 * These imports were created based on recommendations from this CSS-tricks article
 * https://css-tricks.com/snippets/css/using-font-face/#article-header-id-1
 */
/* CiscoSans Thin */
@font-face {
  font-family: cisco-sans;
  font-display: fallback;
  src: url('cisco-sans/CiscoSansTTThin.woff2') format('woff2'),
    url('cisco-sans/CiscoSansTTThin.woff') format('woff');
  font-weight: 100;
}
@font-face {
  font-family: cisco-sans;
  font-display: fallback;
  src: url('cisco-sans/CiscoSansTTThinOblique.woff2') format('woff2'),
    url('cisco-sans/CiscoSansTTThinOblique.woff') format('woff');
  font-weight: 100;
  font-style: oblique;
}

/* CiscoSans Extra Light */
@font-face {
  font-family: cisco-sans;
  font-display: fallback;
  src: url('cisco-sans/CiscoSansTTExtraLight.woff2') format('woff2'),
    url('cisco-sans/CiscoSansTTExtraLight.woff') format('woff');
  font-weight: 200;
}
@font-face {
  font-family: cisco-sans;
  font-display: fallback;
  src: url('cisco-sans/CiscoSansTTExtraLightOblique.woff2') format('woff2'),
    url('cisco-sans/CiscoSansTTExtraLightOblique.woff') format('woff');
  font-weight: 200;
  font-style: oblique;
}

/* CiscoSans Light */
@font-face {
  font-family: cisco-sans;
  font-display: fallback;
  src: url('cisco-sans/CiscoSansTTLight.woff2') format('woff2'),
    url('cisco-sans/CiscoSansTTLight.woff') format('woff');
  font-weight: 300;
}
@font-face {
  font-family: cisco-sans;
  font-display: fallback;
  src: url('cisco-sans/CiscoSansTTLightOblique.woff2') format('woff2'),
    url('cisco-sans/CiscoSansTTLightOblique.woff') format('woff');
  font-weight: 300;
  font-style: oblique;
}

/* CiscoSans Regular */
@font-face {
  font-family: cisco-sans;
  font-display: fallback;
  src: url('cisco-sans/CiscoSansTTRegular.woff2') format('woff2'),
    url('cisco-sans/CiscoSansTTRegular.woff') format('woff');
  font-weight: 400;
}
@font-face {
  font-family: cisco-sans;
  font-display: fallback;
  src: url('cisco-sans/CiscoSansTTRegularOblique.woff2') format('woff2'),
    url('cisco-sans/CiscoSansTTRegularOblique.woff') format('woff');
  font-weight: 400;
  font-style: oblique;
}

/* CiscoSans Bold */
@font-face {
  font-family: cisco-sans;
  font-display: fallback;
  src: url('cisco-sans/CiscoSansTTBold.woff2') format('woff2'),
    url('cisco-sans/CiscoSansTTBold.woff') format('woff');
  font-weight: 700;
}
@font-face {
  font-family: cisco-sans;
  font-display: fallback;
  src: url('cisco-sans/CiscoSansTTBoldOblique.woff2') format('woff2'),
    url('cisco-sans/CiscoSansTTBoldOblique.woff') format('woff');
  font-weight: 700;
  font-style: oblique;
}

/* CiscoSans Heavy */
@font-face {
  font-family: cisco-sans;
  font-display: fallback;
  src: url('cisco-sans/CiscoSansTTHeavy.woff2') format('woff2'),
    url('cisco-sans/CiscoSansTTHeavy.woff') format('woff');
  font-weight: 900;
}
@font-face {
  font-family: cisco-sans;
  font-display: fallback;
  src: url('cisco-sans/CiscoSansTTHeavyOblique.woff2') format('woff2'),
    url('cisco-sans/CiscoSansTTHeavyOblique.woff') format('woff');
  font-weight: 900;
  font-style: oblique;
}
