.deviceStatusPaneToggle {
  display: flex;
  padding-right: 0;
  align-items: center;
  width: 100%;
  background: transparent;
  border: transparent;
  padding: 0.375rem 0;

  &:focus {
    outline: none;
  }
}
