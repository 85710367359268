@import 'fonts/index';

//  START BOOTSTRAP CONFIG
@import 'base';
@import 'components/index';
//  END BOOTSTRAP CONFIG

html,
body,
#root {
  min-height: 100vh;
  max-width: 100vw;
}

// custom typography
@include media-breakpoint-up(sm) {
  html {
    font-size: 13px;
    color: $gray-700;
  }
}

@include media-breakpoint-down(sm) {
  html {
    font-size: 16px;
    color: $gray-700;
  }
}

h1 {
  font-weight: $font-weight-normal;
  color: $gray-900;
  line-height: 35px;
}

h2 {
  font-weight: $font-weight-normal;
  color: $gray-900;
  line-height: 27px;
}

h3 {
  font-weight: $font-weight-light;
  color: $gray-900;
  line-height: 23px;
}

h4 {
  font-weight: $font-weight-normal;
  color: $gray-600;
  line-height: 19px;
}

small {
  color: $gray-600;
  line-height: 13px;
}

a {
  color: $blue;
}

// override boostrap button styles with button mixin
// https://github.com/twbs/bootstrap/blob/f1e6e6e4a19dac0fa08bc235c701cbdc99969eaf/scss/mixins/_buttons.scss#L6
.btn {
  &.btn-primary {
    @include button-variant(
      $blue,
      $blue,
      $blue--darker,
      $blue--darker,
      $blue--darker,
      $blue--darker
    );
  }

  &.btn-secondary {
    @include button-variant($gray-100, $gray-400, $gray-200, $gray-400, $gray-200, $gray-300);
  }

  &.btn-outline-primary {
    @include button-outline-variant($blue--darker, $blue--darker, $blue--light, $blue);
  }

  &.btn-danger {
    @include button-variant(
      $red--darker,
      $red--darker,
      $red--darkest,
      $red--darkest,
      $red--darkest,
      $red--darkest
    );
  }

  &.btn-link.btn-lg {
    font-size: 1.2rem;
    line-height: 27px;
  }
}
