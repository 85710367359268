@import 'theme/base.scss';

.mapMarker {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  .markerContent {
    padding: 4px 3px 3px 3px;
    border-radius: 5px;
    border: 3px solid $green;
    color: white;
    font-weight: 400;
    background-color: $green;
  }
  .markerCaret {
    .caretBorder {
      // border-width (10px) is used to calculate CARET_OFFSET in PlaceDevices.jsx. Remember to keep them in sync if you update this value
      border-width: 10px 8px 0px;
      border-style: solid;
      border-left-color: transparent;
      border-right-color: transparent;
      border-bottom-color: transparent;
      border-top-color: $green;
    }
  }
}
