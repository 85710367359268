@import 'theme/base.scss';

.cardSectionContainer {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1.2em;
  align-items: stretch;
  flex-wrap: nowrap;

  .cardSectionTitle {
    flex: 0 1 7em;
    color: #707173;
  }

  .cardSectionContent {
    flex: 1 0;
  }

  .cardSectionPasscodeContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: stretch;
    div {
      margin-right: 1em;
      margin-bottom: 0.1em;
      &:last-of-type {
        margin-right: 0;
      }
    }

    img {
      height: 1em;
      width: auto;
    }

    button {
      padding-top: 0;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }
}

.cardContainer {
  flex: 1 0;
  flex-basis: 20em;
  max-width: 30em;
  padding-top: 0.5em;
  font-size: 13px;

  .cardHeader {
    text-transform: uppercase;
    color: $gray-50--darker;
    font-size: 11px;
  }
}
