@import 'theme/base.scss';

.profileCard {
  &.installed {
    border-top: 0.2rem solid $green;
    .profileCardSubtitle {
      color: $green--darker;
    }
  }
  &.notInstalled {
    border-top: 0.2rem solid $gray-70--lighter;
    .profileCardSubtitle {
      color: $gray-50--darker;
    }
  }

  &.expired {
    border-top: 0.2rem solid $red;
    .profileCardSubtitle {
      color:$red;
    }
  }

  .accessExpiredMessage {
    color: $red;
  }

  .profileCardHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    .profileCardTitle {
      font-weight: 600;
      font-size: 1.2rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 65%;
    }

    .profileCardSubtitle {
      text-transform: uppercase;
      font-size: 0.8rem;
    }
  }

  .passpointDownload {
    color: $blue;
    cursor: pointer;
  }
}
