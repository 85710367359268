@import 'theme/base.scss';

.deviceLink {
  .icon {
    width: 20px;
    vertical-align: middle;
    text-align: center;
    margin-right: 5px;
    &.mdm {
      font-size: 20px;
    }

    &.trustedAccess {
      font-size: 10px;
    }
  }
}