@import 'theme/base.scss';

.footer {
  margin-top: auto;
  width: 100%;
  background-color: $gray-100;
  border-top: 1px solid $gray-300;
  padding: 1rem;
}

.copyright {
  color: $gray-600;
}

@include media-breakpoint-up(md) {
  .footer {
    display: flex;
    flex-direction: row-reverse;
  }

  .copyright {
    padding: 0 1.5rem;
    border-left: 1px solid $gray-300;
    margin-left: 1.5rem;
    display: flex;
    align-items: center;
  }
}

@include media-breakpoint-down(sm) {
  .footer {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
  }
}
